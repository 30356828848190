export default [
  // {
  //   key: 'name',
  //   label: 'field.name',
  //   type: 'text',
  // },
  // {
  //   key: 'username',
  //   label: 'field.username',
  //   type: 'text',
  // },
  // {
  //   key: 'parentId',
  //   label: 'field.master',
  //   rules: 'required',
  //   type: 'asynSingleSelection',
  //   repository: 'master',
  //   selectionKey: 'id',
  //   selectionLabel: 'username',
  //   clearable: true,
  // },
  // {
  //   key: 'isEnable',
  //   label: 'field.status',
  //   rules: 'required',
  //   type: 'radio',
  //   options: [
  //     { text: 'status.active', value: '1' },
  //     { text: 'status.inactive', value: '0' },
  //   ],
  // },
]
